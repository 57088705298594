
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";

interface resetPass {
  email: any;
  newPassword: string;
}

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute()

    const toast = useToast();

    const submitButton = ref<HTMLElement | null>(null);

    const passwordResets = Yup.object().shape({
      newPassword: Yup.string().min(4).required('Kata Sandi Wajib Diisi'),
    });

    const resetPass = reactive<resetPass>({
      email: route.params.email,
      newPassword: '',
    });

    const onSubmitReset = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Dummy delay
      setTimeout(() => {

        ApiService
        .getWithoutSlug('crmv2/main_auth/auth/create_new_password_forgot_password?otp=' + route.params.otp + '&regId=' + route.params.regId + '&newPassword=' + resetPass.newPassword + '&email=' + route.params.email)
        .then((res) => {

          Swal.fire({
            text: "Kata sandi anda sudah diubah, silahkan login kembali menggunakan email dan kata sandi yang telah dimasukkan",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Baik, saya akan login",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })

          router.push('/sign-in');

        })
        .catch((e) => {
          
          toast.error(e.response.data.detail)

        });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    const check_otp_forgot_pass = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_auth/auth/check_otp_forgot_password/" +
          route.params.otp +
          "/" +
          route.params.regId +
          "/" +
          route.params.email
      )
        .then((res) => {})
        .catch((e) => {});
    };

    onMounted(() => {
      check_otp_forgot_pass();
    });

    return {
      passwordResets,
      onSubmitReset,
      check_otp_forgot_pass,
      submitButton,
      resetPass,
    };
  },
});
